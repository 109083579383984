import React, { useEffect, useRef } from 'react';
import '../about.css';
import { FaNewspaper } from 'react-icons/fa6';
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { Email } from '@mui/icons-material';
import { MdEmail } from "react-icons/md";
import Navbar from './Navbar';
import Footer from './Footer';

const AboutUs = () => {


  return(
  
  
<div class="">

<Navbar/>
  <div class="section-header">
    <div class="container">
    </div>
  </div>

  <div class="container">
    <div class="row">

      <div class="contact-info">
        <div class="contact-info-item">
          <div class="contact-info-icson">
          <FaMapMarkerAlt className="navbar-icon-about" />
          </div>

          <div class="contact-info-content">
            <h4>Adresse</h4>
            <p>Parking casino supermarché ,<br /> 1006 chemin des gourettes, <br />06370 Mouans Sartoux</p>
          </div>
        </div>

        <div class="contact-info-item">
          <div class="contact-info-iscon">
          <FaPhone  className="navbar-icon-about" />
          </div>

          <div class="contact-info-content">
            <h4>Numéro</h4>
            <p>+33 0777361591</p>
          </div>
        </div>

        <div class="contact-info-item">
          <div class="contact-info-icson">
          <MdEmail className="navbar-icon-about" />
          </div>

          <div class="contact-info-content">
            <h4>Email</h4>
            <a href="mailto:gourmandisesaroulettes@gmail.com">gourmandisesaroulettes@gmail.com</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
  
  
  );
};

export default AboutUs;
