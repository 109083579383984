import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import BannerBackground from "../Assets/home-banner-background.png";
import AboutBackground from "../Assets/about-background.png";
import { BounceLoader } from 'react-spinners';
// Import all images for Gateaux
import gateaux1 from "../Assets/gateaux/1.png";
import gateaux2 from "../Assets/gateaux/2.png";
import gateaux3 from "../Assets/gateaux/3.png";
import gateaux4 from "../Assets/gateaux/4.png";
import gateaux5 from "../Assets/gateaux/5.png";
import gateaux6 from "../Assets/gateaux/6.png";
import gateaux7 from "../Assets/gateaux/7.png";
import gateaux8 from "../Assets/gateaux/8.png";
import gateaux9 from "../Assets/gateaux/9.png";
import gateaux10 from "../Assets/gateaux/10.png";
import gateaux11 from "../Assets/gateaux/11.png";
import gateaux12 from "../Assets/gateaux/12.png";
import gateaux13 from "../Assets/gateaux/13.png";
import gateaux14 from "../Assets/gateaux/14.png";
import gateaux15 from "../Assets/gateaux/15.png";
import gateaux16 from "../Assets/gateaux/16.png";
import gateaux17 from "../Assets/gateaux/17.png";
import gateaux18 from "../Assets/gateaux/18.png";
import gateaux19 from "../Assets/gateaux/19.png";
import gateaux20 from "../Assets/gateaux/20.png";

const imageStyle = {
    display: 'block',
    margin: '0 auto', // Centers the image horizontally
    maxWidth: '100%', // Ensure the video respects container size
    maxHeight: '100%', // Ensure the video respects container size
  };
  
   
  
function Gateaux2() {

    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate loading delay with setTimeout
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Adjust the time as needed
  
      // Clear timeout when component unmounts to prevent memory leaks
      return () => clearTimeout(timer);
    }, []);
  
  
    const options = {
      start  : 0,
      perPage: 1,
      type: 'loop',
      rewind: true,
      width: 600, // Adjust the width of the slider
      gap: 10,
      autoplay: true,
      interval: 8000,
      pauseOnHover: true,
      pagination: true, // Enable pagination
  
    };

  return (
    <div>

<Navbar/>
    <div className='home-bannerImage-container'>
                <img src={BannerBackground} alt=""/>
            </div>

    <div className="image-slider-container">
    <Splide options={options} className="splide-container">
          <SplideSlide><img src={gateaux1} alt="Image 0" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux2} alt="Image 1" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux3} alt="Image 2" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux4} alt="Image 3" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux5} alt="Image 4" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux6} alt="Image 5" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux7} alt="Image 6" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux8} alt="Image 7" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux9} alt="Image 8" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux10} alt="Image 99" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux11} alt="Image 9" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux12} alt="Image 10" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux13} alt="Image 11" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux14} alt="Image 12" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux15} alt="Image 13" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux16} alt="Image 14" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux17} alt="Image 15" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux18} alt="Image 16" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux19} alt="Image 17" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={gateaux20} alt="Image 18" style={imageStyle} /></SplideSlide>
        </Splide>

      </div>

    
    <Footer/>
    </div>
  )
}
export default Gateaux2;
