import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { BounceLoader } from 'react-spinners';

// Dynamically import all images from /Assets/boissons
const images = require.context('../Assets/boissons', false, /\.(png|jpe?g|svg)$/);

const imageStyle = {
  display: 'block',
  margin: '0 auto', // Centers the image horizontally
  maxWidth: '100%', // Ensure the video respects container size
  maxHeight: '100%', // Ensure the video respects container size
};

function Boissons2() {
  const [isLoading, setIsLoading] = useState(true);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    // Fetch all images and set them in state
    const fetchedImages = images.keys().map((image) => images(image));
    setImageList(fetchedImages);

    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clear timeout when component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  const options = {
    start: 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600, // Adjust the width of the slider
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true, // Enable pagination
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <BounceLoader color="#36D7B7" loading={isLoading} />
        </div>
      ) : (
        <div className="image-slider-container">
          <Splide options={options} className="splide-container">
            {imageList.map((src, index) => (
              <SplideSlide key={index}>
                <img src={src} alt={`Image ${index}`} style={imageStyle} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      )}
    </div>
  );
}

export default Boissons2;
