import React, { useState } from "react";
import { AiFillStar } from "react-icons/ai";
import jade from "../Assets/jade.png";

function About2() {
  const [showSecondParagraph, setShowSecondParagraph] = useState(false);

  const handleLearnMoreClick = () => {
    setShowSecondParagraph(!showSecondParagraph); // Toggle the state
  };

  return (
    <div className='work-section-wrapper'>
        <div className='work-section-top'>
            <h1 className='primary-heading'>Si je vous en disais un peu plus sur moi…</h1>
        </div>
        <div className='testimonial-section-bottom'>
            <img className='jade-pic' src={jade} alt=''></img>
            {/* <div className='testimonial-starts-container'>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
            </div> */}
            {/* <p className="primary-subheading-about">Si je vous en disais un peu plus sur moi…</p> */}
            <p id="1" className="primary-text-about">
          Je suis Jade, 34 ans, passionnée de pâtisserie et pin-up dans l’âme depuis de nombreuses années. <br/>Maman de 3
          princesses en bas âge, dont une super héroïne, j'aime les défis et mène des combats sans jamais me décourager
          pour arriver au bout de mes rêves. <br/>J'ai les cheveux roses depuis 10 ans bientôt et ma chevelure de princesse,
          c'est uniquement grâce à la best <b><a className="primary-text-about" href="https://www.instagram.com/lamaisondublondbyflorinacannet/" target="_blank" rel="noopener noreferrer">@lamaisondublondbyflorinacannet</a></b>.<br/> J'ai un tempérament de feu et donne tout
          pour ceux que j'aime, mais aussi pour mon travail !! Je suis une vraie acharnée et ne lâche jamais
          l'affaire ! <br/>Ma devise : toujours croire en ses rêves !
        </p>
        {showSecondParagraph && (
          <p id="2" className="primary-text-about">
            La vie a court-circuité mes projets professionnels suite à la maladie d'une de mes poupées en 2019, mais me
            voilà de nouveau en selle pour tous vous régaler.<br/> J'ai eu la chance de participer à un programme sur
            <b><a className="primary-text-about" href="https://www.instagram.com/m6officiel/" target="_blank" rel="noopener noreferrer"> @m6officiel</a></b> en 2017 aux côtés de 
            <b><a className="primary-text-about" href="https://www.instagram.com/cyril_lignac/" target="_blank" rel="noopener noreferrer"> @cyril_lignac</a></b> et je vis de ma passion depuis 2014. Je suis diplômée en
            pâtisserie et adore créer encore et encore !! <br/>En 2023, je réalise enfin mon rêve et ouvre ma pâtisserie
            salon de thé (milles mercis au soutien que j'ai reçu de ma moitié ❤️ et de certains proches qui se
            reconnaîtront 🫶🏼). <br/>Ma passion : réaliser vos demandes les plus folles en pâtisserie ! <br/>Dans ma roulotte en
            bois, vous pourrez venir déguster mes pâtisseries maison dégoulinantes d'amour mais pas seulement…
          </p>
        )}
        <button className="secondary-button" onClick={handleLearnMoreClick}>
            {showSecondParagraph ? "Afficher moins" : "En savoir plus"}
          </button>
        </div>
    </div>
  )
}

export default About2