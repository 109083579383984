import React, { useState } from 'react';
import Navbar from './Navbar';
import BannerBackground from "../Assets/home-banner-background.png";
import Footer from './Footer';

// Import the images
import CarteBrunch from "../Assets/new-files/carte brunch.png";
import HotFreshDrinks from "../Assets/new-files/hot & fresh drink menu 2.png";
import hotDrink from "../Assets/new-files/hot drink menu.png";
import Lunch from "../Assets/new-files/lunch.png";
import Tarifs from "../Assets/new-files/tarifs.png";

// Define an array with imported images
const imageUrls = [CarteBrunch ,HotFreshDrinks , hotDrink, Lunch,Tarifs];

function Carte() {
  const [enlargedIndex, setEnlargedIndex] = useState(null);

  const toggleImageSize = (index) => {
    setEnlargedIndex(index === enlargedIndex ? null : index);
  };

  return (
    <div>
      <Navbar />
      <div>
        <div className='contact-page-wrapper'>
          <div className='home-bannerImage-container'>
            <img src={BannerBackground} alt="" />
          </div>
        </div>
        <div className="carte-container">
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className={`carte-image ${enlargedIndex === index ? 'enlarged' : ''}`}>
              <img src={imageUrl} alt={`Image ${index + 1}`} onClick={() => toggleImageSize(index)} />
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Carte;
