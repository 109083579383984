import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import BannerBackground from "../Assets/home-banner-background.png";
import AboutBackground from "../Assets/about-background.png";
import { BounceLoader } from 'react-spinners';

const imageStyle = {
    display: 'block',
    margin: '0 auto', // Centers the image horizontally
    maxWidth: '100%', // Ensure the video respects container size
    maxHeight: '100%', // Ensure the video respects container size
  };
  
   
  
function Brunch() {
    const videoStyle = {
      display: 'block',
      margin: '0 auto', // Centers the image horizontally
      maxWidth: '100%', // Ensure the video respects container size
      maxHeight: '100%', // Ensure the video respects container size
            };
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate loading delay with setTimeout
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000); // Adjust the time as needed
  
      // Clear timeout when component unmounts to prevent memory leaks
      return () => clearTimeout(timer);
    }, []);
  
  
    const options = {
      start  : 0,
      perPage: 1,
      type: 'loop',
      rewind: true,
      width: 600, // Adjust the width of the slider
      gap: 10,
      autoplay: true,
      interval: 8000,
      pauseOnHover: true,
      pagination: true, // Enable pagination
  
    };

  return (
    <div>
        <Navbar/>
        <div className='work-section-wrapper'>
        <div className='work-section-top'>
        <h1 className='primary-heading'>Bientôt  </h1>

        </div>
    </div>  
    <div className='home-bannerImage-container'>
                <img src={BannerBackground} alt=""/>
            </div>

    
    <div className="image-slider-container">
        {/* <Splide options={options} className="splide-container">
        <SplideSlide>
      <video autoPlay={true} controls loop style={imageStyle}>
        <source src="https://gourmande-is-50s.fr/gallerie/brunch/brunch1.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </SplideSlide>

    <SplideSlide>
      <video autoPlay={true} controls loop style={imageStyle}>
        <source src="https://gourmande-is-50s.fr/gallerie/brunch/brunch2.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </SplideSlide>


    <SplideSlide>
      <video autoPlay={true} controls loop style={imageStyle}>
        <source src="https://gourmande-is-50s.fr/gallerie/brunch/brunch3.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </SplideSlide>

        </Splide> */}
      </div>

    
    
    <Footer/>
    </div>
  )
}
export default Brunch;
