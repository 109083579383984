import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import BannerBackground from "../Assets/home-banner-background.png";
import { BounceLoader } from 'react-spinners';

// Function to dynamically import images and videos from a directory
function importAll(r) {
  let assets = {};
  r.keys().forEach((item) => { assets[item.replace('./', '')] = r(item); });
  return assets;
}

// Import all images and videos from /Assets/brunch directory
const assets = importAll(require.context('../Assets/brunch', false, /\.(png|jpe?g|svg|mp4)$/));

const assetStyle = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '100%',
  maxHeight: '100%',
};

function Brunch2() {
  const [isLoading, setIsLoading] = useState(true);
  const [assetList, setAssetList] = useState([]);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      const loadedAssets = Object.keys(assets).map(key => assets[key]);
      setAssetList(loadedAssets);
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const options = {
    start: 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600,
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true,
  };

  return (
    <div>
      <div className='home-bannerImage-container'>
        <img src={BannerBackground} alt="Banner" />
      </div>

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <BounceLoader color="#36D7B7" loading={isLoading} />
        </div>
      ) : assetList.length > 0 ? (
        <div className="image-slider-container">
          <Splide options={options} className="splide-container">
            {assetList.map((asset, index) => (
              <SplideSlide key={index}>
                {asset.endsWith('.mp4') ? (
                  <video
                    src={asset}
                    alt={`Video ${index}`}
                    style={assetStyle}
                    autoPlay
                    controls
                    loop
                  />
                ) : (
                  <img
                    src={asset}
                    alt={`Image ${index}`}
                    style={assetStyle}
                  />
                )}
              </SplideSlide>
            ))}
          </Splide>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <h2>Coming Soon</h2>
        </div>
      )}
    </div>
  );
}

export default Brunch2;
