import React, { useState } from "react";
import Instagram from "../Assets/instagram.png";
import Uber from "../Assets/uber.png";

import Logo from "../Assets/Logo.png";
import { HiOutlineBars3 } from "react-icons/hi2";
import { AiOutlineHome, AiOutlinePicture, AiOutlineShopping, AiOutlineMail, AiFillInstagram } from "react-icons/ai";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom"; // Import Link
import { IoMail } from "react-icons/io5";
import { FaNewspaper } from "react-icons/fa6";
import { IoImages } from "react-icons/io5";
import { IoHome } from "react-icons/io5";
import { FaMapMarkerAlt } from "react-icons/fa";
import LogoNew from "../Assets/logo-new.jpg";



const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
<div className="divv">
    <div className="nav-logo-container">
    <img src={LogoNew} alt="" className="nav-logo" />
  </div>
    <nav>
      {/* <div className="nav-logo-container">
        <img src={LogoNew} alt="" className="nav-logo" />
      </div> */}

<div className="navbar-links-container">
  <Link className="navbar-links-container" to="/">Home</Link>
  <Link className="navbar-links-container" to="/gallery">Page gallery </Link>
  <Link className="navbar-links-container" to="/carte">Carte </Link>
  <Link className="navbar-links-container" to="/contact">Contact us</Link>

  <Link className="navbar-links-container" to="https://www.instagram.com/gourmande_is_50s/">

        <img src={Instagram} className="instagram-logo" alt="Instagram Logo" />
        </Link>
        <Link className="navbar-links-container" to="https://www.ubereats.com/fr/store/gourmande-is-50s/kDSnXxdZXmuDEGD6286EOQ">

        <img src={Uber} className="uber-logo" alt="Uber Logo" />
        </Link>

  
  {/* <Link to="/contact">Contact us</Link> */}

  {/* <Link to="/cart">
    <BsCart2 className="navbar-cart-icon" />
  </Link> */}
{/* <a href="https://www.instagram.com/gourmande_is_50s" className="navbar-ig">
<AiFillInstagram className="navbar-ig" />

</a>
<a href="https://www.ubereats.com/fr/store/gourmande-is-50s/kDSnXxdZXmuDEGD6286EOQ" target="_blank">
<img className="navbar-icon-uber2" src="https://gourmande-is-50s.fr/gallerie/uber.png" alt="" />

</a> */}
</div>

      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>

      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            <ListItem key="Home" disablePadding>
              <ListItemButton>
                <Link to="/" className="navbar-link">
                  <IoHome className="navbar-icon" />
                  <span className="navbar-text">Home</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Gallerie" disablePadding>
              <ListItemButton>
                <Link to="/gallery" className="navbar-link">
                  <IoImages className="navbar-icon" />
                  <span className="navbar-text">Page gallery</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Carte" disablePadding>
              <ListItemButton>
                <Link to="/carte" className="navbar-link">
                  <FaNewspaper className="navbar-icon" />
                  <span className="navbar-text">Carte</span>
                </Link>
              </ListItemButton>
            </ListItem>
            <ListItem key="Contact" disablePadding>
              <ListItemButton>
                <Link to="/contact" className="navbar-link">
                  <IoMail className="navbar-icon" />
                  <span className="navbar-text">Contact us</span>
                </Link>
              </ListItemButton>
            </ListItem>


            <ListItem key="AboutUs" disablePadding>
              <ListItemButton>
                <Link to="/about" className="navbar-link">
                <FaMapMarkerAlt className="navbar-icon" />
                  <span className="navbar-text">About</span>
                </Link>
                
              </ListItemButton>
            </ListItem>


            <ListItem key="Instagram" disablePadding>
              <ListItemButton>
                <Link to="https://www.instagram.com/gourmande_is_50s" className="navbar-link">
                  {/* <AiFillInstagram className="navbar-icon" /> */}
                  <span className="instagram-link2">Instagram</span>
                </Link>
                
              </ListItemButton>
            </ListItem>

            <ListItem key="Uber" >
              <ListItemButton>
              <Link to="https://www.ubereats.com/fr/store/gourmande-is-50s/kDSnXxdZXmuDEGD6286EOQ" className="navbar-link" target="_blank">
                
                  {/* <AiFillInstagram className="navbar-icon" /> */}
                  <div className="uber-contents">
                  <img className="navbar-icon-uber" src={Uber} alt="" />
                  </div>
                </Link>
                
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
    </div>
  );
};

export default Navbar;
