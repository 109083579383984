import React from "react";
import Logo from "../Assets/Logo.svg";
import { Instagram } from "@mui/icons-material";
import { AiFillInstagram } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div >

<div className="footer-basic">
      <footer>
        {/* <div className="social">
        <a href="https://www.instagram.com/gourmande_is_50s">
<AiFillInstagram className="navbar-ig2" />

</a>
        </div> */}
        {/* <ul className="list-inline">

        <li className="list-inline-item"> <Link to="/">Home</Link></li> 
        <li className="list-inline-item"><Link to="/gallerie">Gallerie </Link></li> 
        <li className="list-inline-item"><Link to="/carte">Carte </Link></li> 
        <li className="list-inline-item"><Link to="/contact">Contact us</Link></li> 
        <li className="list-inline-item"><Link to="/about">About</Link></li> 

          {/* <li className="list-inline-item"><a href="#">Privacy Policy</a></li> 

        </ul>*/}
        
        {/* <p className="copyright">Gourmande is 50’s © 2024</p>  */}
        <p className="copyright">Gourmande is 50's - 07 77 36 15 91</p> 
        <p className="copyright">Parking casino - 1006 chemin des gourettes - 06370 Mouans Sartoux</p> 
        <p className="copyright">SIRET 951 948 348 00010</p> 
        <p className="copyright">        Du mardi au samedi de 9h30 à 18h.
</p> 




        <div className="sig">
  <a href="https://bit.ly/yahya-houaouii" className="sig-link">
  
  {/* <a href="mailto:gourmandisesaroulettes@gmail.com">gourmandisesaroulettes@gmail.com</a> */}

  <p className="sig-text">Made with ❤️ By <b><a className="sig" href="mailto:yahiahwewi@gmail.com">YAHYA</a></b></p>
  </a>
</div>


      </footer>
    </div>

    </div>
  );
};

export default Footer;
