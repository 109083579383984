import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import BannerBackground from "../Assets/home-banner-background.png";
import images from '../Utils/LoadImages';
import { FaTrash } from 'react-icons/fa'; // Import a trash icon from react-icons
import { BounceLoader } from 'react-spinners'; // Import BounceLoader

const imageStyle = {
  display: 'block',
  margin: '0 auto',
  maxWidth: '100%',
  maxHeight: '100%',
  position: 'relative', // Important for absolute positioning of the delete icon
};

const deleteIconStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  cursor: 'pointer',
  backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent background
  borderRadius: '50%',
  padding: '5px',
};

function Lunch2() {
  const [isLoading, setIsLoading] = useState(true); // Manage loading state
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setImageList(Object.keys(images).map(key => images[key]));
      setIsLoading(false);
    }, 2000); // Adjust this time as needed

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const options = {
    start: 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600,
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true,
  };

  const deleteImage = (index) => {
    const newImageList = imageList.filter((_, i) => i !== index);
    setImageList(newImageList);
  };

  return (
    <div>
      <div className='home-bannerImage-container'>
        <img src={BannerBackground} alt="" />
      </div>
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <BounceLoader color="#36D7B7" loading={isLoading} />
        </div>
      ) : (
        <div className="image-slider-container">
          <Splide options={options} className="splide-container">
            {imageList.length > 0 ? (
              imageList.map((image, index) => (
                <SplideSlide key={index}>
                  <div style={{ position: 'relative' }}>
                    <img
                      src={image}
                      alt={`Image ${index}`}
                      style={imageStyle}
                      loading="lazy"
                      // Example of using srcset with lower resolution images
                      srcSet={`${image}?w=600 600w, ${image}?w=1200 1200w`}
                      sizes="(max-width: 600px) 600px, 1200px"
                    />
                    <FaTrash
                      style={deleteIconStyle}
                      onClick={() => deleteImage(index)}
                      title="Delete Image"
                    />
                  </div>
                </SplideSlide>
              ))
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <h2>Coming Soon</h2>
              </div>
            )}
          </Splide>
        </div>
      )}
    </div>
  );
}

export default Lunch2;
