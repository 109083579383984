import React, { useState } from 'react';
import Gourmands from './Gourmands';
import Classics from './Classics';
import FreshDrinks from './FreshDrinks';
import Brunch from "../Assets/brunch.png";
import Lunch from "../Assets/lunch.png";
import Boissons from "../Assets/boissons.png";
import Gateaux from "../Assets/gateaux.png";
import Buffet from "../Assets/buffet.png";

import { Link } from 'react-router-dom';



function Menu() {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const handleButtonClick = (componentName) => {
    setSelectedComponent(componentName);
  };

  return (
    <div >


    <div className='work-section-wrapper'>
        <div className='work-section-top'>
        {/* <h1 className='primary-heading'>Notre menu </h1> */}

        </div>
    </div>

    <div className="menu">
    
    <div class="button-container2">
    <Link className="button-menu2" to={"/lunch"}>
        <img className='lunch-img' src={Lunch} alt="" />
        
    </Link>

    <Link className="button-menu2" to={"/brunch"}>
        <img src={Brunch} alt="" />
    </Link>

 



 <Link className="button-menu2" to={"/buffets"}>
        <img src={Buffet} alt="" />
    </Link>

    <Link className="button-menu2" to={"/gateaux"}>
        <img src={Gateaux} alt="" />
    </Link>

    <Link className="button-menu2" to={"/boissons"}>
        <img src={Boissons} alt="" />
    </Link>
</div>


      {/* <div className='items'>
        {selectedComponent === 'Gourmands' && <Gourmands />}
        {selectedComponent === 'Classics' && <Classics />}
        {selectedComponent === 'FreshDrinks' && <FreshDrinks />}
      </div> */}
    </div>    </div>

  );
}

export default Menu;
