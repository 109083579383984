import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import BannerBackground from "../Assets/home-banner-background.png";
import AboutBackground from "../Assets/about-background.png";
import { BounceLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import Brunch1 from "../Assets/brunch.png";
import Lunch1 from "../Assets/lunch.png";
import Boissons1 from "../Assets/boissons.png";
import Lunch from './Lunch';
import Lunch2 from './Lunch2';
import gateaux from "../Assets/gateaux.png";
import buffet from "../Assets/buffet.png";

import Brunch from './Brunch';
import Boissons from './Boissons';
import Boissons2 from './Boissons2';
import Brunch2 from './Brunch2';
import Buffets from './Buffets';
import Gateaux from './Gateaux';
import Gateaux2 from './Gateaux2';

// muted={true}
// muted={true}
// muted={true}
const imageStyle = {
  display: 'block',
  margin: '0 auto', // Centers the image horizontally
  maxWidth: '100%', // Ensure the video respects container size
  maxHeight: '100%', // Ensure the video respects container size
};

function ImageSlider() {
  const [activeComponent, setActiveComponent] = useState(null);

  // Function to handle onClick event for each button
  const handleClick = (componentName) => {
    setActiveComponent(componentName);
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clear timeout when component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);


  const options = {
    start  : 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600, // Adjust the width of the slider
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true, // Enable pagination

  };

  return (
    <div>
      <Navbar />
      <div className='gallery-page-wrapper'>
      
        {/* <h1 className='primary-heading'>Découvrez Notre Galerie</h1> */}
      </div>
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" loading="lazy"  />
      </div>
      <div className='home-bannerImage-container'>
        <img src={BannerBackground} alt="" loading="lazy"  />
      </div>
      
      <div className="menu">
    
      <div className="button-container3">
      <Link className="button-menu2" to="/gallery" onClick={() => handleClick('lunch2')}>
        <img className='lunch-img' src={Lunch1} alt="" loading="lazy"  />
      </Link>

      <Link className="button-menu2" to="/gallery" onClick={() => handleClick('brunch2')}>
        <img className='lunch-img' src={Brunch1} alt="" loading="lazy"   />
      </Link>

      {/* <Link className="button-menu2" to="/gallery" onClick={() => handleClick('brunch2')}>
        <img src={Brunch1} alt="" loading="lazy"  />
      </Link> */}

      <Link className="button-menu2" to="/gallery" onClick={() => handleClick('boissons2')}>
        <img src={Boissons1} alt="" loading="lazy"  />
      </Link>

      <Link className="button-menu2" to="/gallery" onClick={() => handleClick('gateaux')}>
        <img src={gateaux} alt="" loading="lazy"  />
      </Link>


      <Link className="button-menu2" to="/gallery" onClick={() => handleClick('Buffets')}>
        <img src={buffet} alt="" loading="lazy"  />
      </Link>


      {/* Render the active component based on state */}
    </div>
</div>

      <div className="image-slider-container">

        {activeComponent === 'lunch2' && <Lunch2 />}
      {activeComponent === 'brunch2' && <Brunch2/>}
      {activeComponent === 'boissons2' && <Boissons2 />}
      {activeComponent === 'Buffets' && <Buffets />}
      {activeComponent === 'gateaux' && <Gateaux />}



      </div>

      <Footer />
    </div>
  );
}

export default ImageSlider;
