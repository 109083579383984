import React, { useState, useEffect } from 'react';
import './App.css';
import Home from './Components/Home';
import About from './Components/About';
import Work from './Components/Work';
import About2 from './Components/About2';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Classics from './Components/Classics';
import { ToastContainer } from 'react-toastify';
import { BounceLoader } from 'react-spinners';
import ImageSlider from './Components/ImageSlider';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Contact2 from './Components/Contact2';
import Carte from './Components/Carte';
import SimpleMap from './Components/AboutUs';
import AboutUs from './Components/AboutUs';
import Lunch from './Components/Lunch';
import Brunch from './Components/Brunch';
import Boissons from './Components/Boissons';
import Gateaux2 from './Components/Gateaux2';
import Buffets from './Components/Buffets';
import FileUpload from './Components/FileUpload';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clear timeout when component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <div className="loading-container">
          <BounceLoader color="#ff0a0a" />
        </div>
      ) : (
        <Router>
          <Routes>
            <Route path="/gallery" element={<ImageSlider />} />
            <Route path="/contact" element={<Contact2 />} />
            <Route path="/carte" element={<Carte />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/lunch" element={<Lunch />} />
            <Route path="/brunch" element={<Brunch />} />
            <Route path="/boissons" element={<Boissons/>} />
            <Route path="/gateaux" element={<Gateaux2/>} />
            <Route path="/buffets" element={<Buffets/>} />

            <Route path="/" element={<Home />} />
            {/* Additional routes can go here */}
            {/* <Route path="/work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/about2" element={<About2 />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/classics" element={<Classics />} />
            <Route path="/footer" element={<Footer />} /> */}
          </Routes>
        </Router>
      )}
      {/* <Toast/> */}
      <ToastContainer />

    </div>
  );
}

export default App;
