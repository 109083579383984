import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import BannerBackground from "../Assets/home-banner-background.png";
import AboutBackground from "../Assets/about-background.png";
import { BounceLoader } from 'react-spinners';

// Import all images for Boissons
import boiss1 from "../Assets/boissons/boiss1.jpg";
import boiss2 from "../Assets/boissons/boiss2.jpg";
import boiss3 from "../Assets/boissons/boiss3.jpg";
import boiss4 from "../Assets/boissons/boiss4.jpg";
import boiss5 from "../Assets/boissons/boiss5.jpg";
import boiss6 from "../Assets/boissons/boiss6.jpg";
import boiss7 from "../Assets/boissons/boiss7.jpg";
import boiss8 from "../Assets/boissons/boiss8.jpg";
import boiss9 from "../Assets/boissons/boiss9.jpg";
import boiss10 from "../Assets/boissons/boiss10.jpg";
import boiss11 from "../Assets/boissons/boiss11.jpg";

const imageStyle = {
  display: 'block',
  margin: '0 auto', // Centers the image horizontally
  maxWidth: '100%', // Ensure the video respects container size
  maxHeight: '100%', // Ensure the video respects container size
};

function Boissons() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clear timeout when component unmounts to prevent memory leaks
    return () => clearTimeout(timer);
  }, []);

  const options = {
    start: 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600, // Adjust the width of the slider
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true, // Enable pagination
  };

  return (
    <div>
      <Navbar />
      <div className='home-bannerImage-container'>
        <img src={BannerBackground} alt="" />
      </div>

      <div className="image-slider-container">
        <Splide options={options} className="splide-container">
          <SplideSlide><img src={boiss1} alt="Image 0" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss2} alt="Image 1" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss3} alt="Image 2" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss4} alt="Image 3" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss5} alt="Image 4" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss6} alt="Image 5" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss7} alt="Image 6" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss8} alt="Image 7" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss9} alt="Image 8" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss10} alt="Image 99" style={imageStyle} /></SplideSlide>
          <SplideSlide><img src={boiss11} alt="Image 9" style={imageStyle} /></SplideSlide>
        </Splide>
      </div>

      <Footer />
    </div>
  )
}
export default Boissons;
