import React from 'react'
import { AiFillStar } from "react-icons/ai";

function Testimonial() {
  return (
    <div className='work-section-wrapper'>
        <div className='work-section-top'>
            <p className='primary-subheading'>Testimonial</p>
            <h1 className='primary-heading'>Ce qu'ils disent</h1>
            <p className="primary-text">
            Découvrez ce que nos clients satisfaits disent de leurs expériences avec nous, découvrez pourquoi ils reviennent toujours pour en avoir plus.
</p>
        </div>
        <div className='testimonial-section-bottom'>

        <img className='testimonial-image' src='https://lh3.googleusercontent.com/a-/ALV-UjVkOZc_dheIodOLhXEthUEnlprU9PbPS1kSa5Ck0o0zU-G-=w68-h68-p-rp-mo-br100' alt=''></img>
            <div className='testimonial-starts-container'>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>

            </div>
            <h2>Katy A</h2>
            <i>C'est un plaisir d'avoir ce lieu atypique près de chez nous. Petits et grands s'y régalent ! L'ambiance est hyper chaleureuse. Les pâtisseries de Jade sont une tuerie. Le détour s'impose

</i>
 <br/>
<img className='testimonial-image' src='https://lh3.googleusercontent.com/a-/ALV-UjUbw1Y7RBVbJDM-3P5O1G6bCg_tb5fWlMW99MeFN3btUNY=w68-h68-p-rp-mo-br100' alt=''></img>
            <div className='testimonial-starts-container'>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>

            </div>
            <h2>Ingrid Von meiss</h2>
            <i>Un accueil hyper chaleureux, des beaux gâteaux hyper gourmands, des bagels savoureux !.. Jade est un Amour de personne et met bcp d'Amour dans sa divine cuisine !

</i>  
<br/>
            <img className='testimonial-image' src='https://lh3.googleusercontent.com/a/ACg8ocJl_FUnQPKvtUEe7P8LyktubHo68H8Fpf-o_z58sLrK=w68-h68-p-rp-mo-br100' alt=''></img>
            <div className='testimonial-starts-container'>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>

            </div>
            <h2>Nathalie Eyraud</h2>
            <i>Accueil chaleureux, une super pâtissière qui a à cœur de faire partager sa passion.
Des muffins exceptionnels, tant au niveau du goût que de l’originalité. Des cupcakes délicats et des brownies succulents.
Découverte d’il y a 4 jours seulement, mais déjà retournée 3 fois !
La roulotte Gourmande is 50’s mérite vraiment d’être connue et reconnue.
</i>            <br/>
<br/>
<a className='avis' href='https://www.google.com/search?client=safari&sca_esv=c9bece44728e7380&hl=fr-fr&cs=0&output=search&q=Gourmande+is+50%E2%80%99s&ludocid=14480851642936413103&lsig=AB86z5XjI_1pJVfHxz4OnS-1frgW&kgs=e94d3e16a7363dc1&shndl=-1&source=sh/x/loc/act/m4/3#lrd=0x12cc295ff551320b:0xc8f651d8ae5b4baf,1,,,,' target="_blank">
<button type='submit' className='secondary-button'>Ajouter votre avis</button>
</a>
        </div>
        
    </div>
  )
}

export default Testimonial