import React, { useState, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Navbar from './Navbar';
import Footer from './Footer';
import BannerBackground from "../Assets/home-banner-background.png";
import { BounceLoader } from 'react-spinners';

// Function to dynamically import images from a directory
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

// Import all images from /Assets/buffets directory
const images = importAll(require.context('../Assets/buffets', false, /\.(png|jpe?g|svg)$/));

const imageStyle = {
  display: 'block',
  margin: '0 auto', // Centers the image horizontally
  maxWidth: '100%', // Ensure the image respects container size
  maxHeight: '100%', // Ensure the image respects container size
};

function Buffets() {
  const [isLoading, setIsLoading] = useState(true);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      const loadedImages = Object.keys(images).map(key => images[key]);
      setImageList(loadedImages);
      setIsLoading(false);
    }, 2000); // Adjust the time as needed

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const options = {
    start: 0,
    perPage: 1,
    type: 'loop',
    rewind: true,
    width: 600,
    gap: 10,
    autoplay: true,
    interval: 8000,
    pauseOnHover: true,
    pagination: true,
  };

  return (
    <div>
    
      <div className='home-bannerImage-container'>
        <img src={BannerBackground} alt="" />
      </div>

      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <BounceLoader color="#36D7B7" loading={isLoading} />
        </div>
      ) : imageList.length > 0 ? (
        <div className="image-slider-container">
          <Splide options={options} className="splide-container">
            {imageList.map((image, index) => (
              <SplideSlide key={index}>
                <img src={image} alt={`Buffet ${index}`} style={imageStyle} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <h2 className='primary-heading'>Bientôt</h2>
        </div>
      )}

    </div>
  );
}

export default Buffets;
