import React from 'react'
import cake from "../Assets/cakee.png";
import ChooseMeals from "../Assets/choose-image.png";
import DeliveryMeals from "../Assets/delivery-image.png";
import gr from "../Assets/testt4.png";

function FreshDrinks() {

  
  const workInfoData = [
    {
      image: cake,
      title: "Gourmandise",
      image2: ChooseMeals
    },
    {
      image: ChooseMeals,
      title: "Customizable Orders",
      text: "Tailor your meal to your liking with our customizable options, allowing you to create a dining experience that suits your preferences perfectly.",
    },
    {
      image: DeliveryMeals,
      title: "Good Fast Deliveries",
      text: "We prioritize speedy deliveries,From kitchen to your table in record time, our commitment to swift service means you'll never have to wait long for your delicious meal to arrive.",
    },
  ];
  return (

<div className="menu" id="freshdrinks">
        <h2 className="menu-group-heading">Fresh Drinks </h2>
        <div className="menu-group">
          {/* Item 1 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>
            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">THE Glace</span>
                <span className="menu-item-price">4,00 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Item 2 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>
            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Citronnade</span>
                <span className="menu-item-price">4,00 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Item 3 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>
            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Ice Coffee</span>
                <span className="menu-item-price">4,00 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Fresh Drink Item 4 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>

            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Lait Fraise</span>
                <span className="menu-item-price">3,50 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Fresh Drink Item 5 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>

            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Eau gazeuse</span>
                <span className="menu-item-price">2,00 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Fresh Drink Item 6 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>

            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Canotte</span>
                <span className="menu-item-price">2,50 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>

          {/* Fresh Drink Item 7 */}
          <div className="menu-item">
<svg className="menu-item-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="24" height="24">
  <circle cx="128" cy="128" r="120" fill="#FFA500" />
</svg>

            <div className="menu-item-text">
              <h3 className="menu-item-heading">
                <span className="menu-item-name">Limonade</span>
                <span className="menu-item-price">3,50 €</span>
              </h3>
              <p className="menu-item-desc">
              </p>
            </div>
          </div>
        </div>
      </div>
      
      
      
      )

}
export default FreshDrinks ;