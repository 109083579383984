// Contact.js
import React from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BannerBackground from "../Assets/home-banner-background.png";
import Navbar from './Navbar';
import Footer from './Footer';

function Contact2() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_dqfrwc3', 'template_0g8fgxs', e.target, 'MtuKRPDRnVSjvb4mY')
      .then((result) => {
        // console.log(result.text);
        toast.success('Votre email a été envoyé ', {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: "slide",

          });
      }, (error) => {
        console.log(error.text);
      });

    e.target.reset();
  }

  return (

<div>
<Navbar/>
<ToastContainer />

  {/* <Navbar/> */}
  <div className='contact-page-wrapper'>
    <div className='home-bannerImage-container'>
      <img src={BannerBackground} alt="" />
    </div>

    <h1 className='primary-heading'>Besoin d'un devis ? </h1>
    <h1 className='primary-heading-contact'>Une question sur notre roulotte ?</h1>
    <h1 className='primary-heading-contact2'>Contactez-nous via ce formulaire, une réponse vous sera apportée rapidement.</h1>

    <form className='contact-form-container' onSubmit={sendEmail}>
  <input type='text' name='from_name' placeholder='Votre Nom' required />
  <input type='email' name='reply_to' placeholder='email@example.com' required />
  <input type='tel' name='phone_number' placeholder='Numéro de téléphone' required />
  <select name='object' required>
    {/* <option value=''>Sélectionnez un objet</option> */}
    <optgroup label="Sélectionnez un devis">
      <option value='devis_buffet'>Devis Buffet</option>
      <option value='devis_gateau'>Devis Gâteau</option>
    </optgroup>
    <option value='autre'>Autre</option>
  </select>
  <textarea name='message' placeholder='Votre message' required></textarea>
  <button type='submit' className='secondary-button'>Envoyer</button>
</form>
  </div>
  <Footer/>
</div>

  );
}

export default Contact2;
