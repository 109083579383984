import React from 'react'
import Navbar from './Navbar'
import BannerBackground from "../Assets/home-banner-background.png";
import { FiArrowRight } from "react-icons/fi";
import cake1 from "../Assets/cake1.png";
import title from "../Assets/title.png";
import Cakes from "../Assets/cup-cakes.png";
import Desc from "../Assets/cup-cakes.png";
import Desc2 from "../Assets/desc2.png";
import FileUpload from '../Components/FileUpload';

import jade from "../Assets/jade.png";
// import Home from './Home';
import About from './About';
import Work from './Work';
import About2 from './About2';
import Contact from './Contact';
import Footer from './Footer';
import { ToastContainer } from 'react-toastify';
import ImageSlider from './ImageSlider';
import Carte from './Carte';
import AboutUs from './AboutUs';
import { BounceLoader } from 'react-spinners';
import NavbarTest from './NavbarTest';
import Testimonial from './Testimonial';

function Home() {
  return (
    <div className='home-container'>
        {/* <NavbarTest/> */}
                <Navbar/>

        <div className='home-text-section-home'>

        <img className='img-' src={title} alt=''/>
        </div>

        <div className='home-banner-container'>
            <div className='home-bannerImage-container'>
                <img src={BannerBackground} alt=""/>
            </div>
            
            <div className='home-text-section'>
            <div className='home-image-section'>
                <img className='img-cake' src={Desc2} alt=''/>
            </div>
                {/* <h1 className='primary-heading'>
                Gourmande is 50’s  
                </h1>
                <h1 className='primary-heading'>
                Gourmande is 50’s  
                </h1> */}
                {/* <h1 className='primary-heading'>
                Gourmande is 50’s
                                </h1> */}
                {/* <p className='primary-text'>
                Explorez un voyage gustatif inoubliable à bord de notre roulotte Gourmand. <br></br>Avec son charme ambulant et ses saveurs exquises, laissez-vous séduire par cette expérience gourmande sur roues.
                </p> */}
                {/* <button className='secondary-button'>
                    Order Now <FiArrowRight/>{""}
                </button> */}
            </div>
            <div className='home-image-section'>
                <img className='img-cake' src={Cakes} alt=''/>
            </div>
        </div>
        <div class="salon-the">
  <p class="primary-subheading-home2">Salon de thé ouvert du mardi au samedi de 9h30 à 18h.</p>
  <p class="primary-subheading-home2">Retrouvez-y des boissons gourmandes, chaudes ou <br/> froides, du sucré mais également du salé.</p>
  <p class="primary-subheading-home2">Nous proposons aussi nos services de traiteur salé ou sucré pour vos événements.<br/> N’hésitez pas à nous appeler ou à nous envoyer un mail via l’onglet de contact.</p>
</div>

        <Work/>
          <ToastContainer />
          {/* <About2/> */}
          <About/>
          {/* <Carte/> */}
          <Testimonial/>

          {/* <Contact/> */}
          {/* <ImageSlider/> */}
          {/* <AboutUs/> */}

          <Footer/>

          <h1>File Upload Test</h1>
      <FileUpload />

    </div>
  )
}

export default Home